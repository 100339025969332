<script lang="ts">
export default {
  name: 'SettingsMenu',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, computed, ref, Ref } from 'vue';
import PopoverMenu from '@/components/atoms/Popover/PopoverMenu/Index.vue';
import PopoverMenuItem from '@/components/atoms/Popover/PopoverMenuItem/Index.vue';
import PopoverMenuLink from '@/components/atoms/Popover/PopoverMenuItem/PopoverMenuLink.vue';
import ChevronBackButton from '@/components/atoms/ChevronBackButton/Index.vue';
import { SettingsMenu } from '@/json_schema_types/sidebar_navigation';

const props = defineProps<{ settings: SettingsMenu; collapsed: boolean; highlighted?: boolean }>();

const menuOpen: Ref<boolean> = ref(false);
const faIconString = computed(() => {
  return menuOpen.value || props.highlighted
    ? `fa-solid ${props.settings.icon}`
    : `fa-regular ${props.settings.icon}`;
});
</script>

<template>
  <li class="settings-menu">
    <button
      aria-label="Click to open settings menu"
      aria-haspopup="true"
      :aria-expanded="menuOpen"
      aria-controls="settings-popover-menu"
      :class="[
        'side-navigation__settings-button',
        { 'side-navigation__settings-button--highlighted': props.highlighted },
      ]"
      @click.prevent="menuOpen = !menuOpen"
      @keyup.enter="menuOpen = !menuOpen"
    >
      <FontAwesomeIcon :icon="faIconString" size="lg" class="fa-fw" />
      <span :class="{ 'visually-hidden': collapsed }"> {{ settings?.label }} </span>
    </button>
    <Teleport to=".vue--sidebar-navigation">
      <PopoverMenu
        id="settings-popover-menu"
        position="top"
        toggle-element-selector="side-navigation__settings-button"
        :style="{
          left: collapsed
            ? 'calc(var(--side-navigation-width) + 0.5rem)'
            : 'calc(var(--side-navigation-width-expanded) + 0.5rem)',
          bottom: '0',
        }"
        :model-value="menuOpen"
        @update:model-value="menuOpen = $event"
      >
        <ChevronBackButton sr-label="Close" @click.prevent="menuOpen = false" />
        <PopoverMenuItem padding="0.5rem 2.5rem">
          <span class="settings-popover-menu__header h4">{{ settings.label }}</span>
        </PopoverMenuItem>
        <template
          v-for="(item, itemIndex) in settings.children"
          :key="`settings-menu-item-${itemIndex}`"
        >
          <PopoverMenuItem :id="itemIndex" padding="0.5rem 2rem"
            ><PopoverMenuLink :item="item"
          /></PopoverMenuItem>
        </template>
      </PopoverMenu>
    </Teleport>
  </li>
</template>

<style lang="scss" scoped>
.settings-menu {
  color: inherit;
}

.side-navigation__settings-button {
  &:hover {
    background-color: var(--navigation-hover-color);
  }

  &--highlighted,
  &--highlighted:hover {
    background-color: var(--navigation-highlight-color);
    font-weight: bold;
  }
}
</style>
