<script lang="ts">
export default {
  name: 'TopNavigationBar',
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, onMounted, ref } from 'vue';
import AddNew from '@/components/molecules/AddNew/Index.vue';
import NavigationHeaderItem from '@/components/atoms/NavigationHeaderItem/Index.vue';
import UserAccountMenu from '@/components/molecules/UserAccountMenu/Index.vue';
import AnnouncementsSidebar from '@/components/organisms/AnnouncementsSidebar/Index.vue';
import QuickSearchInput from '@/components/molecules/QuickSearchInput/Index.vue';
import QuickSearchResults from '@/components/molecules/QuickSearchResults/Index.vue';
import NotificationsDropdown from '@/components/molecules/NotificationsDropdown/Index.vue';
import { useTestHelpers } from '@/composables/useTestHelpers';
import { useI18n } from '@/composables/useI18n';
import { breakpointsTailwind, onClickOutside, useBreakpoints } from '@vueuse/core';

import { ICurrentUser } from '@/types/Navigation';
import {
  AddNewLinkGroup,
  SupportAndUpdates,
  UserMenu,
  UtilityNavItems,
} from '@/json_schema_types/sidebar_navigation';

interface Props {
  rootPath: string;
  searchPath: string;
  contentDiscoverabilitySearch: boolean;
  companyName: string;
  logoUrl: string;
  customLogoUrl?: string;
  userSignedIn?: boolean;
  utilityNavItems: UtilityNavItems;
  addNewItems?: AddNewLinkGroup[];
  currentUser: ICurrentUser;
  userMenu?: UserMenu;
  supportAndUpdates?: SupportAndUpdates;
  isSideNavCollapsed: boolean;
}

defineProps<Props>();

const { generateTestId } = useTestHelpers();
const { t } = useI18n();

const breakpoints = useBreakpoints(breakpointsTailwind);
const smallerThanLg = breakpoints.smaller('lg');

const searchTerm = ref('');
const searchInput = ref(null);
const searchResults = ref(null);
const searchResultsOpen = ref(false);

const showSearchResults = () => {
  searchResultsOpen.value = true;
};

onMounted(() => {
  onClickOutside(searchResults, hideSearchResults, { ignore: [searchInput] });
});

const hideSearchResults = () => {
  searchResultsOpen.value = false;
};
</script>

<template>
  <header :class="['top-navigation-bar', { 'top-navigation-bar--expanded': !isSideNavCollapsed }]">
    <a
      :href="rootPath"
      :class="[
        'top-navigation-bar__company-logo',
        { 'top-navigation-bar__company-logo--expanded': !isSideNavCollapsed },
      ]"
    >
      <img
        v-if="!isSideNavCollapsed && customLogoUrl"
        :src="customLogoUrl"
        :alt="companyName"
        class="top-navigation-bar__company-custom-logo-image"
      />
      <img
        v-else
        :src="logoUrl"
        :alt="companyName"
        class="top-navigation-bar__company-logo-image"
      />
    </a>
    <div ref="searchInput" class="top-navigation-bar__quick-search">
      <QuickSearchInput
        v-model="searchTerm"
        type="slim"
        :bordered="false"
        :search-path="searchPath"
        :content-discoverability-search="contentDiscoverabilitySearch"
        @focus="showSearchResults"
      >
        <div v-show="searchResultsOpen" class="top-navigation-bar__quick-search-results-container">
          <QuickSearchResults ref="searchResults" v-model="searchTerm" />
        </div>
      </QuickSearchInput>
    </div>

    <div class="top-navigation-bar__spacer"></div>

    <div
      v-if="utilityNavItems"
      :class="[
        'top-navigation-bar__menu-items',
        { 'top-navigation-bar__menu-items--compact': smallerThanLg },
      ]"
    >
      <company-switcher
        v-if="utilityNavItems.company_switcher"
        :search-endpoint="utilityNavItems.company_switcher.search_endpoint"
        :admin="utilityNavItems.company_switcher.admin"
        :default-error-msg="t('errors.internal_server_error.something_went_wrong')"
        menu-direction="right"
      />

      <AddNew :add-new-items="addNewItems" />

      <NavigationHeaderItem
        v-if="supportAndUpdates"
        id="announcements-toggle"
        :icon="supportAndUpdates.icon"
        :aria-label="supportAndUpdates.label"
        :data-test-id="generateTestId('top navigation bar', supportAndUpdates.label.toLowerCase())"
      />

      <NavigationHeaderItem
        v-if="utilityNavItems.beta_features"
        :icon="utilityNavItems.beta_features.icon"
        :href="utilityNavItems.beta_features.url"
        :aria-label="utilityNavItems.beta_features.label"
        :badge-count="utilityNavItems.beta_features.count"
        :data-test-id="
          generateTestId('top navigation bar', utilityNavItems.beta_features.label.toLowerCase())
        "
      />

      <NavigationHeaderItem
        v-if="utilityNavItems.inboxes"
        :icon="utilityNavItems.inboxes.icon"
        :href="utilityNavItems.inboxes.url"
        :aria-label="utilityNavItems.inboxes.label"
        :badge-count="utilityNavItems.inboxes.count"
        :data-test-id="
          generateTestId('top navigation bar', utilityNavItems.inboxes.label.toLowerCase())
        "
      />

      <NavigationHeaderItem
        v-if="utilityNavItems.bookmarks"
        :icon="utilityNavItems.bookmarks.icon"
        :href="utilityNavItems.bookmarks.url"
        role="button"
        tabindex="0"
        :aria-label="utilityNavItems.bookmarks.label"
        :badge-count="utilityNavItems.bookmarks.count"
        :data-test-id="
          generateTestId('top navigation bar', utilityNavItems.bookmarks.label.toLowerCase())
        "
      />

      <NotificationsDropdown
        v-if="utilityNavItems.notifications"
        :utility-nav-items="utilityNavItems"
      />

      <UserAccountMenu
        v-if="userMenu"
        :user-menu="userMenu"
        :current-user="currentUser"
        :company="{ name: companyName, logo: logoUrl }"
        :data-test-id="generateTestId('top navigation bar', 'user account menu')"
      />

      <AnnouncementsSidebar
        v-if="supportAndUpdates"
        :support-only="supportAndUpdates.support_only"
        :admin-owner-user="supportAndUpdates.admin_owner_user"
        :chat-bot-url="supportAndUpdates.chatbot_url"
        :announcements-endpoint="supportAndUpdates.announcements_endpoint"
        :webinars-endpoint="supportAndUpdates.webinars_endpoint"
        :endpoint="supportAndUpdates.endpoint"
      />
    </div>
  </header>
</template>

<style lang="scss" scoped>
.top-navigation-bar {
  width: 100%;
  min-height: var(--navigation-height);
  position: fixed;
  top: 0;
  color: var(--navigation-text-color);
  background-color: var(--navigation-color);
  z-index: 1030;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.4rem;
  padding-right: 1.4rem;

  &--expanded {
    padding-left: 1rem;
  }

  &__company-logo {
    display: inline-block;
    margin-right: 1rem;
    width: calc(var(--side-navigation-width) - 2.4rem);
    transition: all 0.3s ease-in;

    &--expanded {
      width: calc(var(--side-navigation-width-expanded) - 2rem);
    }
  }

  &__menu-items {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;

    &--compact {
      gap: 0rem;
    }
  }

  //Temp fixes for company switcher
  :deep(.hidden-nav-switcher) {
    display: flex;
  }
  :deep(.hidden-nav-switcher .navbar__link--dropdown) {
    color: var(--navigation-text-color);
    font-size: inherit;
    padding: 1rem !important;
    color: var(--navigation-text-color);
    border-radius: 4px;
    display: flex;
    align-items: center;

    &:hover,
    &:focus,
    &:active {
      background-color: var(--navigation-hover-color);
    }
  }
}

.top-navigation-bar__company-logo-image {
  height: 35px;
  width: calc(var(--side-navigation-width) - 2.4rem);
  border-radius: 100%;
}

.top-navigation-bar__company-custom-logo-image {
  height: 4.2rem;
  width: 100%;
  border-radius: 10px;
}

.top-navigation-bar__spacer {
  display: flex;
  flex: 1;
}

.top-navigation-bar__quick-search {
  position: relative;
  display: flex;
  flex: 1;
  flex-basis: max-content;
  margin-right: auto;
}

.top-navigation-bar__quick-search-results-container {
  position: absolute;
  top: 44px;
  left: 0;
  width: 100%;
  max-width: 500px;
  min-width: 300px;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  max-height: calc(100vh - var(--navigation-height));
  overflow: hidden;
  display: flex;
}
</style>
